












































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { User, UserDetails } from '@/types';

@Component({
  components: {
    UserCredits: () => import('@/components/UserCredits.vue'),
    UserAdoptions: () => import('@/components/UserAdoptions.vue'),
    AddUserDialog: () => import('@/components/AddUserDialog.vue'),
  },
})
export default class UsersAdminDialog extends Vue {
  users: UserDetails[] = [];

  dialog = false
  loading = false

  headers = [
    { text: 'Nome', value: 'fullName', sortable: false },
    { text: 'Email', value: 'email', sortable: false },
    {
      text: 'Fiorini',
      value: 'credits',
      sortable: false,
      align: 'start',
    },
    { text: 'Adozioni', value: 'adoptions', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  get value(): boolean {
    return this.$store.getters.usersAdminDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get filteredUsers(): UserDetails[] {
    return this.users
      // eslint-disable-next-line arrow-body-style
      ? this.users.filter((u) => {
        // Filtro sugli archiviati
        // if (!this.filters.archived && p.archived) return false;

        // Filtro sulla fase
        // eslint-disable-next-line no-prototype-builtins
        // if (this.filters.phase && !p.phases.hasOwnProperty(this.filters.phase)) return false;

        // TODO: Campo di ricerca
        // if (this.filters.search && this.filters.search.trim()
        //   && !p.name[this.userLanguage]
        //     .toLowerCase().includes(this.filters.search.toLowerCase())) return false;

        return true;
      })
      : [];
  }

  @Watch('value')
  async onValueChanged(val: boolean) {
    const debugPrefix = 'UsersAdminDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (val) {
      // Recupera l'elenco degli utenti
      this.loading = true;
      this.users = await this.$store.dispatch('getUsers');
      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'UsersAdminDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'usersAdmin');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }

  onDelete() {
    this.dialog = false;
  }

  onEdit() {
    this.dialog = false;
  }

  onDetails() {
    this.dialog = false;
  }

  onAddUser() {
    this.$store.dispatch('openDialog', 'addUser');
    this.dialog = false;
  }
}
