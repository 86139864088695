var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-users-admin-dialog"},[_c('v-dialog',{ref:"dialog",attrs:{"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"dialog-card"},[_c('h1',{staticClass:"dialog-name"},[_vm._v("Gestione utenti")]),_c('div',{staticClass:"dialog-descr body-1 pb-4 mt-4"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"item-key":"id","items-per-page":10,"footer-props":{
              itemsPerPageOptions: [10, 20, 30, -1],
            }},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.credits",fn:function(ref){
            var item = ref.item;
return [_c('UserCredits',{attrs:{"user":item}})]}},{key:"item.adoptions",fn:function(ref){
            var item = ref.item;
return [_c('UserAdoptions',{attrs:{"user":item}})]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-list-item-title',[_vm._v("Elimina")])],1)],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary","disabled":"","dark":false},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary","disabled":"","dark":false},on:{"click":function($event){return _vm.onDetails(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1)],1)]}}],null,true)})],1)]),_c('v-card-actions',{staticClass:"dialog-actions"},[_c('div',{staticClass:"d-none d-sm-flex flex-grow-1"},[_c('v-btn',{attrs:{"rounded":"","depressed":"","outlined":"","color":"#D19555","dark":""},on:{"click":_vm.onCancel}},[_vm._v(" Chiudi ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","depressed":"","color":"success","dark":""},on:{"click":_vm.onAddUser}},[_vm._v(" Aggiungi utente ")])],1),_c('div',{staticClass:"d-flex d-sm-none flex-grow-1"},[_c('v-btn',{attrs:{"small":"","rounded":"","depressed":"","outlined":"","color":"#D19555","dark":""},on:{"click":_vm.onCancel}},[_vm._v(" Chiudi ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","rounded":"","depressed":"","color":"success","dark":""},on:{"click":_vm.onAddUser}},[_vm._v(" Aggiungi utente ")])],1)])],1)],1),_c('AddUserDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }